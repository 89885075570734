import { DefaultTheme } from "styled-components";

const commonStyles = {
  breakpoints: {
    xs: 375,
    sm: 576,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1440,
    extra: 1600,
  },
};

export const theme: DefaultTheme = {
  ...commonStyles,
};
