import * as S from "./ComingSoon.styles";
import comingSoon from "assets/images/coming-soon.png";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { PuffLoader } from "react-spinners";

const ComingSoon = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(document.readyState !== "complete");

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <S.Page>
      <CSSTransition
        classNames="fade"
        timeout={200}
        in={isLoading}
        unmountOnExit
      >
        <S.LoadingOverlay>
          <PuffLoader color="#fff" loading size={60} />
        </S.LoadingOverlay>
      </CSSTransition>

      <S.ComingSoon src={comingSoon} />
      <S.Content>
        <S.MoreInfoText>
          To reach out for more information, email
        </S.MoreInfoText>
        <S.EmailText>enquiry@moonpass.com </S.EmailText>
      </S.Content>
    </S.Page>
  );
};

export default ComingSoon;
