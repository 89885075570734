import { ThemeProvider } from "styled-components";

import "./App.css";
import { theme } from "./config/themes";
import ComingSoon from "./pages/ComingSoon";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ComingSoon />
    </ThemeProvider>
  );
}

export default App;
