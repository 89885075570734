import styled from "styled-components";
import spaceBg from "assets/images/space-bg.png";
import spaceMobileBg from "assets/images/mobile-bg.png";

export const Page = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${spaceBg});
  background-position: center;
  background-size: cover;
  color: #fff;

  .fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-image: url(${spaceMobileBg});
  }
`;

export const MoreInfoText = styled.span`
  font-family: "Oxanium";
  font-size: 20px;
  text-align: center;
  line-height: 1;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.59);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 20px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
  }
`;

export const EmailText = styled.span`
  font-family: "Palanquin";
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  line-height: 1;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.55);
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 27px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 20px;
  }
`;

export const ComingSoon = styled.img`
  width: 780px;
  position: absolute;
  top: calc(50% - 110px);
  left: 50%;
  transform: translate(-45%, -50%);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 600px;
    top: calc(50% - 70px);
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: calc(50% - 100px);
    width: 480px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 90%;
    top: calc(50% - 200px);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: calc(50% + 30px);
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    top: calc(50% + 50px);
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: 50%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    top: calc(50% - 120px);
  }
`;

export const LoadingOverlay = styled.div`
  background-color: #000;
  position: absolute;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;
